/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        "use strict";

        $('.ui.ipc.sidebar').sidebar('attach events', '#menuButton');

        // so that the admin bar moves with push menu
        $('#wpadminbar').addClass('fixed');

        $('#userMenu').dropdown();
        $('.ui.accordion').accordion();
        $('.ui.checkbox').checkbox();

        // Stuff for registration form
        var $form = $('.ui.form.register-form');
        var fieldSetCount = $('.js-form-step').length;
        var currentStep = 0;
        var stepsWidth = 0;
        var widths = [];

        var jumpToStep = function(step) {
          // Calculate the value for the margin-left based on the width array
          var leftValue = step === 0 ? 0 : '-' + widths[step - 1] + 'px';

          $('.step[data-step="'+(step - 1)+'"]').addClass('completed');

          // Switch the active class on the step navigation
          $('.js-steps-nav')
            .find('.step.active')
            .removeClass('active');
          $('.js-steps-nav')
            .find('.step[data-step="'+step+'"]')
            .addClass('active');

          // Transition the form
          $('.js-form-steps')
            .stop()
            .animate({
              marginLeft: leftValue
            }, 500, function() {
            });
        };

        function setWidths() {
          stepsWidth = 0;
          widths = [];

          $('.js-form-step').each(function(i){
            var $step     = $(this);
            stepsWidth    += $step.outerWidth();
            widths[i]     = stepsWidth;
          });

          $('.js-form-steps').width(stepsWidth + 10);
          jumpToStep(currentStep);
        }
        setWidths(stepsWidth, widths, currentStep);


        function doneResizing(){
          setWidths(stepsWidth, widths, currentStep);
        }
        var resizeId;
        $(window).resize(function() {
            clearTimeout(resizeId);
            resizeId = setTimeout(doneResizing, 500);
        });

        $('.message .close')
          .on('click', function() {
            $(this)
              .closest('.message')
              .transition('fade')
            ;
          })
        ;

        /* to avoid problems in IE, focus the first input of the form */
        $('.js-register-form').children(':first').find(':input:first').focus();

        $('.js-steps-nav').show();

        function validateForm() {
          $form.form('validate form');
          return $form.form('is valid');
        }

        function validateStep(step) {
          var valid = true;
          $('.form-step[data-step="'+ step +'"] .field').each(function(i, field) {
            if ($(field).hasClass('error')) {
              valid = false;
            }
          });
          return valid;
        }

        $('.js-steps-nav .step').each(function() {
          $(this).click(function(e) {
            if ($(this).hasClass('completed')) {
              currentStep = $(this).data('step');
              validateForm();
              if (validateStep(currentStep) === true) {
                var previousStep = currentStep;
                if (currentStep <= fieldSetCount) {
                  currentStep++;
                  jumpToStep(currentStep);
                }
              }
              jumpToStep(currentStep);
            }
          });
        });

        // Listen for clicks on the next and back buttons
        $('.step-next').click(function(e) {
          validateForm();
          if (validateStep(currentStep) === true) {
            var previousStep = currentStep;
            if (currentStep <= fieldSetCount) {
              currentStep++;
              jumpToStep(currentStep);
            }
          }
          e.preventDefault();
        });

        $('.step-back').click(function(e) {
          var previousStep = currentStep;
          if (currentStep > 0) {
            currentStep--;
            jumpToStep(currentStep);
          }
          e.preventDefault();
        });

        // Attach a listender to each 'Next' button so that when the user
        // hits tab it clicks that button, thus transitioning the form
        // to the next step.
        $('.step-next').each(function(){
          var $button = $(this);
          $button.keydown(function(e){
            if (e.keyCode === 9){
              $(e.target).closest('.js-form-step').find('.step-next').click();
              /* force the blur for validation */
              $(this).blur();
              e.preventDefault();
            }
           });
        });

        // create variable for global access
        var formValidation;

        // function re-creates Form Validation object
        // Semantic UI overwrites the actual object, so to change
        // validation requirements, it must be re-established as a new object
        function createFormValidation(requirements) {
          formValidation = {
            inline: true,
            keyboardShortcuts: false,
            fields: {
              first_name : 'empty',
              last_name : 'empty',
              company_or_organization: 'empty',
              user_phone: 'empty',
              user_address_1: 'empty',
              user_city: 'empty',
              user_state: requirements.state,
              user_province: requirements.province,
              user_country: 'empty',
              user_zip: 'empty',
              user_agreement: 'checked',
              user_login : ['minLength[3]', 'empty'],
              user_email : ['empty'],
              user_pass   : ['minLength[6]', 'empty'],
              user_pass_confirm    : ['match[user_pass]', 'empty']
            }
          };
          // Set the new validation requirements
          $form.form(formValidation);
        }
        // create default form requirements
        createFormValidation({state: null, province: null});


        // toggles if state or province required by passing in appropriate argument to createFormValidation function
        function toggleFormValidation(section) {
          if (section === 'state') {
            createFormValidation({state: 'empty', province: null});
          }
          if (section === 'province') {
            createFormValidation({state: null, province: 'empty'});
          }
        }

        // set default values for ease of testing
        // $form.form('set values', {
        //   first_name : 'John',
        //   last_name : 'Doe',
        //   company_or_organization: 'ABC Company',
        //   user_phone: '555-123-1234',
        //   user_address_1: '111 Elm St',
        //   user_city: 'Fakeville',
        //   user_zip: '12345',
        //   user_login : 'johndoe',
        //   user_email : 'john.doe@fake.com',
        //   user_pass   : 'johndoe',
        //   user_pass_confirm    : 'johndoe'
        // });


        // create jQuery variables for form objects.
        var $country_select = $('.js-user_country select');
        var $section_province = $('.js-user_province');
        var $section_state = $('.js-user_state');

        $country_select.on('change', function() {

          // When USA is selected, show state dropdown and require it for validation
          if ($country_select.val() === 'US') {
            $section_province.hide();
            $section_state.show();
            toggleFormValidation('state');

          } else {
          // when non-USA country selected, show province / region input box for custom input, and then require it.
            $section_province.show();
            $section_state.hide();
            toggleFormValidation('province');
          }
        });


        $('input[notab=notab]').on('keydown', function(e) {
          if (e.keyCode === 9) {  e.preventDefault(); }
        });

        $( "input[name='create_zd_account']").change(function() {
          if(this.value === "yes"){
            $('.ui.dimmer').toggleClass('active');
            $.ajax({
              type: "POST",
              data: "zendesk_email="+$("input[name='user_email']").val(),
              url: "/wp-content/themes/ipc-devportal/zendesk-account-lookup.php"
            }).done(function(response) {
              if(response === "yes"){
                $('.ui.dimmer').toggleClass('active');
                $('.submit.button').prop('disabled', false);
                $('.zendesk-account-check').hide();
              } else {
                $('.ui.dimmer').toggleClass('active');
                console.log('Account is NOT unique');
                $('.zendesk-account-check').show();
                $('.submit.button').prop('disabled', true);
              }
            });
          }
          else {
            $('.submit.button').prop('disabled', false);
            $('.zendesk-account-check').hide();
          }
        });

        // Listen for changes to the email and check with Zendesk
        $( "input[name='user_email']").change(function() {
          $.ajax({
            type: "POST",
            data: "zendesk_email="+$("input[name='user_email']").val(),
            url: "/wp-content/themes/ipc-devportal/zendesk-account-lookup.php"
          }).done(function(response) {
            if(response === "yes"){
              // Account is Unique
              $form.form('set value', 'create_zd_account', 'yes');
              $('.submit.button').prop('disabled', false);
              $('.zendesk-account-check').hide();
            } else {
              // Account is NOT unique
              $form.form('set value', 'create_zd_account', 'no');
              $('.zendesk-account-check').show();
            }
          });
        });

        $('#add-app-dropdown')
          .dropdown({
               action: 'hide'
          })
        ;


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'downloads': {
      init: function() {

        var getUrlParameter = function getUrlParameter(sParam) {
          var sPageURL = decodeURIComponent(window.location.search.substring(1)),
              sURLVariables = sPageURL.split('&'),
              sParameterName,
              i;
          for(i = 0; i < sURLVariables.length; i++) {
              sParameterName = sURLVariables[i].split('=');
              if (sParameterName[0] === sParam) {
                  return sParameterName[1] === undefined ? true : sParameterName[1];
              }
          }
        };


        var showCatParam = getUrlParameter('showcat');

        var $catSelect = $('#catSelect');

        $catSelect.dropdown({
          action: function(text, value) {
            console.log('hello');
            window.location.href = "/downloads/?showcat=" + value;
          }
        });
        $catSelect.dropdown('set selected', showCatParam);


        $('#catSelectButton').click(function(){
          var selectedCat = $('#catSelect').dropdown('get value')[0];
          window.location.href = "/downloads/?showcat="+selectedCat;
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
